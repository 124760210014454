<template>
  <v-dialog v-model="isOpenDialog" :max-width="(!querySuccess && !queryFail) ? 800 : 600"
            :fullscreen="$vuetify.breakpoint.xs"
            :transition="($vuetify.breakpoint.xs) ? 'dialog-bottom-transition' : 'dialog-transition'"
            scrollable>
    <v-card>
      <v-card-title v-if="!querySuccess && !queryFail">
        <span v-if="mode === 'add'" class="secondary--text">เพิ่มข้อมูลตรวจวัด</span>
        <span v-if="mode === 'edit'" class="secondary--text">แก้ไขข้อมูลตรวจวัด</span>
        <span v-if="mode === 'view'" class="secondary--text">ข้อมูลตรวจวัด</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!querySuccess && !queryFail"/>
      <v-card-text class="pa-0">
        <v-form ref="myForm" :readonly="mode === 'view'">
          <div v-show="!querySuccess && !queryFail" class="py-3 py-sm-3 px-6 px-sm-9 black--text">
            <v-row class="mt-1">
              <v-col cols="4" sm="2" class="d-flex align-center">สถานที่เก็บ</v-col>
              <v-col cols="8" sm="" md="">
                <v-text-field v-model="samplingLocation" readonly dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="2" class="d-flex align-center">วันที่เก็บตัวอย่าง</v-col>
              <v-col cols="8" sm="4" md="3">
                <my-date-picker v-model="samplingDate" min="" :readonly="mode === 'view'"></my-date-picker>
              </v-col>
              <v-col cols="4" sm="2" class="d-flex align-center">วันที่วิเคราะห์</v-col>
              <v-col cols="8" sm="4" md="3">
                <my-date-picker v-model="labDate" min="" :readonly="mode === 'view'"></my-date-picker>
              </v-col>
            </v-row>
<!--            <v-row>-->
<!--              <v-col cols="4" sm="2" class="d-flex align-center">วันที่รับเอกสาร</v-col>-->
<!--              <v-col cols="8" sm="4" md="3">-->
<!--                <my-date-picker v-model="documentDate" :readonly="mode === 'view'"></my-date-picker>-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-divider class="my-4"></v-divider>
            <v-row v-if="type === '1' || type === '3'">
              <v-col cols="4" sm="2" class="d-flex align-center">ประเภทจุดเก็บ <span class="error--text">*</span></v-col>
              <v-col cols="8" sm="10">
                <v-select v-model="samplingPointType" :items="parameterTypeList" :rules="[rules.required]" item-text="name" item-value="id" dense outlined hide-details></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="2" class="d-flex align-center">จุดเก็บตัวอย่าง</v-col>
              <v-col cols="8" sm="10">
                <v-text-field v-model="samplingPoint" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="2" class="d-flex align-center">ลักษณะตัวอย่าง</v-col>
              <v-col cols="8" sm="10">
                <v-text-field v-model="samplingDescription" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="type === '3'">
              <v-col cols="4" sm="2" class="d-flex align-center">เชื่อเพลิงที่ใช้</v-col>
              <v-col cols="8" sm="">
                <v-select v-model="stackFuelType" :items="stackFuelRef" item-value="id" item-text="name" dense outlined hide-details @change="stackFuelDetail=''"></v-select>
              </v-col>
              <v-col cols="4" sm="2" class="d-flex align-center">ระบุเชื้อเพลิงที่ใช้</v-col>
              <v-col cols="8" sm="">
                <v-text-field v-model="stackFuelDetail" :disabled="stackFuelType!==6" :background-color="(stackFuelType!==6) ? 'grey lighten-3' : 'white'"
                              dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="d-flex align-center">พารามิเตอร์</v-col>
            </v-row>
            <v-row>
              <v-col cols="1" class="d-none d-md-flex"></v-col>
              <v-col cols="12" md="11">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr class="primary">
                      <th class="text-center white--text" scope="col" style="min-width: 165px;">Parameter</th>
                      <th class="text-center white--text" scope="col" style="min-width: 115px;">สัญลักษณ์</th>
                      <th class="text-center white--text" scope="col" style="min-width: 115px;">ค่าการตรวจวัด</th>
                      <th class="text-center white--text" scope="col" style="min-width: 100px;">หน่วย</th>
                      <th class="text-center white--text" scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(key, index) in parameterId">
                      <tr :key="key + index">
                        <td>
                          <v-autocomplete v-model="parameterId[index]" :items="parameterList" item-text="name" item-value="id"
                                          dense outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                          <v-select v-model="signId[index]" :items="signList" item-text="name" item-value="id"
                                    dense outlined hide-details></v-select>
                        </td>
                        <td>
                          <v-text-field v-model="value[index]" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                          <div v-if="parameterId[index] && parameterList">
                            <v-select v-model="unit[index]" :items="(parameterList.find(item => item.id === parameterId[index]) || { unit: [] }).unit"
                                      item-text="name" item-value="id" dense outlined hide-details>
                            </v-select>
                          </div>
                        </td>
                        <td>
                          <v-icon @click="removeParameterRow(index)">mdi-delete</v-icon>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-btn class="mt-3" @click="addParameterRow()">
                  <v-icon class="mr-2">mdi-plus</v-icon> เพิ่ม
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-show="querySuccess" class="py-9 success--text align-center justify-center text-center"
               style="font-size: 20px;">
            บันทึกสำเร็จ
          </div>
          <div v-show="queryFail" class="py-9 warning--text align-center justify-center text-center"
               style="font-size: 20px;">
            บันทึกไม่สำเร็จ ({{ errorMessage }})
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-end pb-1" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!querySuccess && !queryFail && mode!=='view'" class="ml-3" color="search-item white--text" elevation="0"
                 @click="confirm()">
            บันทึก
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MyDatePicker from '../../myDatePicker'
import { mapState } from 'vuex'
import projectConstant from '@/assets/js/projectConstant'

export default {
  name: 'report',
  components: { MyDatePicker },
  props: {
    isShow: Boolean,
    mode: String,
    data: Object,
    type: String,
    sampling_location: String
  },
  mounted () {
    this.$store.dispatch('ref/getStackFuelRef')
  },
  data () {
    return {
      isOpenDialog: false,
      querySuccess: false,
      queryFail: false,
      errorMessage: '',
      parameterList: [],
      parameterTypeList: [],
      signList: [
        {
          id: '0',
          name: ''
        },
        {
          id: '1',
          name: '<'
        },
        {
          id: '2',
          name: '>'
        },
        {
          id: '3',
          name: '='
        }
      ],
      // --,
      id: '0',
      samplingLocation: '',
      reportCode: '',
      documentDate: '',
      labDate: '',
      samplingDate: '',
      samplingPointType: '',
      samplingPoint: '',
      samplingDescription: '',
      stackFuelType: '',
      stackFuelDetail: '',
      // --
      parameterId: [],
      signId: [],
      value: [],
      unit: [],
      // --
      rules: projectConstant.rules
    }
  },
  methods: {
    addParameterRow () {
      this.parameterId.push(null)
      this.signId.push('3')
      this.value.push('')
      this.unit.push('')
    },
    removeParameterRow (index) {
      this.parameterId.splice(index, 1)
      this.signId.splice(index, 1)
      this.value.splice(index, 1)
    },
    async confirm () {
      const isValid = this.$refs.myForm.validate()
      if (!isValid) return false
      const requiredPayload = { report_id: this.id, uuid: this.uuid, type: this.type }
      const optPayload = {
        sampling_point_type: (this.type === '1' || this.type === '3') ? this.samplingPointType || '' : '',
        sampling_location: this.sampling_location,
        sampling_point: this.samplingPoint || '',
        sampling_date: this.samplingDate || '',
        lab_date: this.labDate || '',
        document_date: this.documentDate || '',
        sampling_description: this.samplingDescription || '',
        param: this.parameterId || [],
        param_sign: this.signId || [],
        value: this.value || [],
        unit: this.unit || []
      }
      if (this.type === '3') {
        optPayload.stack_fuel = this.stackFuelType
        if (String(this.stackFuelType) === '6') {
          optPayload.stack_fuel_detail = this.stackFuelDetail
        }
      }
      const result = await this.$store.dispatch('eReport/setInnerEReportModal', { ...requiredPayload, ...optPayload })
      if (result === true) {
        this.sendSuccessSignal()
        this.querySuccess = true
      } else {
        this.errorMessage = result
        this.queryFail = true
      }
    },
    sendSuccessSignal () {
      this.$emit('success', true)
    }
  },
  watch: {
    async isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.querySuccess = false
        this.queryFail = false
        this.errorMessage = ''
        if (this.data.id) {
          this.id = this.data.id
          const requiredKey = { uuid: this.uuid, sampling_location_id: this.data.id }
          if (this.$refs.myForm) this.$refs.myForm.reset()
          const data = await this.$store.dispatch('eReport/getInnerEReportModal', { ...requiredKey })
          this.samplingLocation = data.samplingLocation || ''
          this.reportCode = data.reportCode || ''
          this.documentDate = (data.documentDate || '').split(' ')[0]
          this.labDate = (data.labDate || '').split(' ')[0]
          this.samplingDate = (data.samplingDate || '').split(' ')[0]
          this.samplingPoint = data.samplingPoint || ''
          this.samplingDescription = data.samplingDescription || ''
          if (this.type === '3') {
            this.stackFuelType = data.stackFuelType ? data.stackFuelType.id : ''
            this.stackFuelDetail = data.stackFuelDetail || ''
            this.samplingPointType = parseInt(data.samplingPointType)
          }
          if (this.type === '1') {
            this.samplingPointType = parseInt(data.samplingPointType)
          }
          // --
          this.parameterId = []
          this.signId = []
          this.value = []
          this.unit = []
          Object.keys(data.parameters).forEach(key => {
            this.parameterId.push(data.parameters[key].id)
            this.signId.push(data.parameters[key].signId)
            this.value.push(data.parameters[key].value)
            this.unit.push(data.parameters[key].unitId || '')
          })
          if (Object.keys(data.parameters).length === 0) {
            this.parameterId = [null]
            this.signId = [null]
            this.value = [null]
          }
        } else {
          this.id = 0
          this.samplingPointType = ''
          this.samplingLocation = this.data.samplingLocation || ''
          this.reportCode = ''
          this.documentDate = ''
          this.labDate = ''
          this.samplingDate = ''
          this.samplingPoint = ''
          this.samplingDescription = ''
          this.stackFuelType = ''
          this.stackFuelDetail = ''
          // --
          this.parameterId = [null]
          this.signId = [null]
          this.value = [null]
        }
      }
      if (this.$refs.myForm) {
        this.$refs.myForm.resetValidation()
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    async type (to, from) {
      if (to !== from) {
        const promiseParameter = this.$store.dispatch('ref/getEntireParameter', { type: to })
        let promiseType = null
        if (to === '1' || to === '3') {
          promiseType = this.$store.dispatch('ref/getEReportSamplingType', { type: to })
        }
        this.parameterList = await promiseParameter
        this.parameterTypeList = (promiseType) ? await promiseType : []
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState('ref', ['stackFuelRef'])
  }
}
</script>

<style scoped>
.row + .row {
  margin-top: 4px;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
