var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"d-flex pr-1 headContainer",staticStyle:{"height":"64px","width":"100%"}},[_c('v-icon',{staticClass:"pr-2 pr-sm-0 d-flex d-sm-none",on:{"click":function($event){return _vm.$router.push({name:'EReport'})}}},[_vm._v(" mdi-chevron-left ")]),_c('div',{staticClass:"pb-4 pt-2 namespaced"},[_c('div',{staticStyle:{"font-size":"24px","color":"rgb(0, 35, 111)","line-height":"1.2"}},[_vm._v(" "+_vm._s(_vm.locationName)+" ")]),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px","text-overflow":"ellipsis","white-space":"nowrap","width":"100%","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.location)+" ")])])],1),_c('div',{staticClass:"pa-1 d-flex align-center",staticStyle:{"min-height":"40px","width":"100%","color":"white","background-color":"#B7A676"}},[_c('div',{staticClass:"d-flex pa-1",staticStyle:{"height":"100%"}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-information-outline")]),_c('div',{staticClass:"ml-2"},[_vm._v("ข้อมูลตรวจวัด")])],1),_c('v-btn',{staticClass:"ml-2",staticStyle:{"background-color":"rgba(255, 255, 255, 0.75)"},attrs:{"color":"primary--text"},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" เพิ่มข้อมูล ")],1)],1),_c('v-data-table',{staticClass:"pt-3 px-3",staticStyle:{"height":"calc(100% - 64px - 43px)"},attrs:{"id":"ereport_detail_dataTable","headers":_vm.headers.concat( _vm.parameterHeaders),"items":_vm.dataList,"items-per-page":999,"disable-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 myPencil",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"myTrash",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.samplingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToThai((item.samplingDate || '').split(' ')[0]))+" ")]}},{key:"item.labDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToThai((item.labDate || '').split(' ')[0]))+" ")]}},_vm._l((_vm.parameterHeaders),function(obj){return {key:("item." + (obj.value)),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.parameters[obj.data_key]) ? item.parameters[obj.data_key].sign + ' ' + item.parameters[obj.data_key].value : '-')+" ")]}}}),{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"48px"}},[(_vm.uuid === '')?_c('span',{staticClass:"grey--text"},[_vm._v(" กรุณาเข้าสู่ระบบเพื่อใช้งาน ")]):_c('span',[_vm._v("ไม่พบข้อมูล")])])]},proxy:true}],null,true)}),_c('report-dialog',{attrs:{"isShow":_vm.openDialog,"mode":_vm.dialogMode,"data":_vm.dialogItem,"type":_vm.type,"sampling_location":String(_vm.id)},on:{"isShowSignal":_vm.handleDialogSignal,"success":function($event){return _vm.search()}}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.openDeleteDialog),callback:function ($$v) {_vm.openDeleteDialog=$$v},expression:"openDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.openDeleteDialog=false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"py-9 black--text text-center",staticStyle:{"font-size":"20px"}},[(!_vm.isFinishDelete && !_vm.isFailDelete)?_c('span',[_vm._v(" ยืนยันการลบ ")]):(_vm.isFinishDelete)?_c('span',{staticClass:"success--text"},[_vm._v("ลบสำเร็จ")]):(_vm.isFailDelete)?_c('span',{staticClass:"warning--text"},[_vm._v("ลบไม่สำเร็จ")]):_vm._e()])]),_c('v-card-actions',[_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.openDeleteDialog=false}}},[_vm._v(" ปิด ")]),(!_vm.isFinishDelete && !_vm.isFailDelete)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"search-item white--text","elevation":"0"},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" ยืนยัน ")]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }