<template>
  <div style="height: 100%; width: 100%;">
    <div class="d-flex pr-1 headContainer" style="height: 64px; width: 100%;">
      <v-icon class="pr-2 pr-sm-0 d-flex d-sm-none" @click="$router.push({name:'EReport'})">
        mdi-chevron-left
      </v-icon>
      <div class="pb-4 pt-2 namespaced" style="">
        <div style="font-size: 24px; color: rgb(0, 35, 111); line-height: 1.2;">
          {{ locationName }}
        </div>
        <div class="grey--text" style="font-size: 13px; text-overflow: ellipsis; white-space: nowrap; width: 100%; overflow: hidden;">
          {{ location }}
        </div>
      </div>
    </div>
    <div class="pa-1 d-flex align-center" style="min-height: 40px; width: 100%; color: white; background-color: #B7A676;">
      <div class="d-flex pa-1" style="height: 100%;">
        <v-icon class="white--text">mdi-information-outline</v-icon>
        <div class="ml-2">ข้อมูลตรวจวัด</div>
      </div>
      <v-btn class="ml-2" color="primary--text" style="background-color: rgba(255, 255, 255, 0.75);"
             @click="addItem()">
        <v-icon>mdi-plus</v-icon>
        เพิ่มข้อมูล
      </v-btn>
    </div>
    <v-data-table id="ereport_detail_dataTable" class="pt-3 px-3"
                  :headers="[...headers, ...parameterHeaders]" :items="dataList" :items-per-page="999"
                  disable-sort fixed-header hide-default-footer mobile-breakpoint="0" style="height: calc(100% - 64px - 43px);">
      <template v-slot:item.actions="{ item }">
<!--        <v-icon class="mr-2" @click="viewItem(item)">-->
<!--          mdi-magnify-->
<!--        </v-icon>-->
        <v-icon class="mr-2 myPencil" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon  class="myTrash" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.samplingDate="{ item }">
        {{ dateToThai((item.samplingDate || '').split(' ')[0]) }}
      </template>
      <template v-slot:item.labDate="{ item }">
        {{ dateToThai((item.labDate || '').split(' ')[0]) }}
      </template>
      <template v-for="obj in parameterHeaders" v-slot:[`item.${obj.value}`]="{ item }">
        {{ (item.parameters[obj.data_key]) ? item.parameters[obj.data_key].sign + ' ' + item.parameters[obj.data_key].value : '-' }}
      </template>
      <template v-slot:no-data>
        <div class="d-flex align-center justify-center" style="height: 48px;">
              <span v-if="uuid === ''" class="grey--text">
                กรุณาเข้าสู่ระบบเพื่อใช้งาน
              </span>
          <span v-else>ไม่พบข้อมูล</span>
        </div>
      </template>
    </v-data-table>
    <report-dialog :isShow="openDialog" :mode="dialogMode" :data="dialogItem" :type="type" :sampling_location="String(id)"
                   v-on:isShowSignal="handleDialogSignal" v-on:success="search()"></report-dialog>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">
              ยืนยันการลบ
            </span>
            <span v-else-if="isFinishDelete" class="success--text">ลบสำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ลบไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import reportDialog from '../components/dialog/ereport/reportDialog'
import utils from '../assets/js/utils'

export default {
  name: 'Report',
  components: { reportDialog },
  beforeRouteEnter (to, from, next) {
    if (!to.query.id || to.query.isSamplingPoint === undefined || !to.query.type) {
      console.warn(to.query)
      next({ name: 'EReport' })
    } else {
      next()
    }
  },
  async mounted () {
    this.getMeta()
    await this.get()

    const dom = document.getElementById('ereport_detail_dataTable')
    const target = dom.getElementsByClassName('v-data-table__wrapper')[0]
    target.addEventListener('scroll', this.onReportListScroll, { passive: true })
  },
  beforeRouteLeave (to, from, next) {
    // this page has been cached in app.vue
    // if next page is not EReport, remove EReport cache
    // for more information see
    //     App.vue :: <keep-alive>
    //     router/index.js :: meta: { keepAlive }
    //     store/index.js :: state: { keepAliveResetSignal }
    if (to.name === 'EReport') {
      next()
    } else {
      next()
      this.$store.dispatch('refreshKeepAlive')
    }
  },
  data () {
    return {
      id: '0',
      is_sampling_point: false,
      type: '0',
      locationName: '',
      location: '',
      // --
      headers: [
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 95
        },
        // {
        //   text: 'เลขที่รายงาน',
        //   value: 'reportCode',
        //   sortable: false
        // },
        {
          text: 'จุดเก็บตัวอย่าง',
          value: 'samplingPoint',
          sortable: false
        },
        {
          text: 'จังหวัด',
          value: 'provinceName',
          sortable: false
        },
        {
          text: 'ช่วงเวลาเก็บตัวอย่าง',
          value: 'samplingDate',
          sortable: false,
          width: 115
        },
        {
          text: 'วันที่วิเคราะห์',
          value: 'labDate',
          sortable: false,
          width: 115
        }
      ],
      apiParameter: {},
      apiMeasurement: [],
      openDialog: false,
      dialogMode: '',
      dialogItem: {},
      toBeDeleteId: 0,
      openDeleteDialog: false,
      isFinishDelete: false,
      isFailDelete: false,
      // --
      currentPage: 1,
      hasNextPage: false,
      itemCount: 0,
      maxPage: 0,
      isFetching: false
    }
  },
  methods: {
    dateToThai (date) {
      return utils.dateToThai(date)
    },
    getMeta () {
      this.id = this.$route.query.id
      this.isSamplingPoint = this.$route.query.isSamplingPoint
      this.type = this.$route.query.type
    },
    search () {
      this.currentPage = 1
      this.get()
    },
    async get () {
      this.isFetching = true
      const payload = { uuid: this.uuid, sampling_location_id: this.id, is_sampling_point: this.is_sampling_point, type: this.type }
      const optPayload = { page: this.currentPage }
      const result = await this.$store.dispatch('eReport/getInnerEReportList', { ...payload, ...optPayload })
      if (this.currentPage === 1) {
        this.apiMeasurement = result.measurements || []
        if (result.measurements.length > 0) {
          this.locationName = result.measurements[0].samplingLocation
        }
        this.apiParameter = result.parameters || {}
        this.itemCount = result.itemCount || 0
        this.maxPage = result.maxPage || 0
      } else {
        const measurements = result.measurements || {}
        const parameters = result.parameters || {}
        this.apiMeasurement = [...this.apiMeasurement, ...measurements]
        this.apiParameter = { ...this.apiParameter, ...parameters }
      }
      this.hasNextPage = result.hasNextPage || false
      this.isFetching = false
    },
    addItem () {
      this.dialogItem = { samplingLocation: this.locationName }
      this.openDialog = true
      this.dialogMode = 'add'
    },
    viewItem (item) {
      this.dialogItem = item
      this.openDialog = true
      this.dialogMode = 'view'
    },
    editItem (item) {
      this.dialogItem = item
      this.openDialog = true
      this.dialogMode = 'edit'
    },
    deleteItem (item) {
      this.toBeDeleteId = item.id
      this.openDeleteDialog = true
    },
    async confirmDelete () {
      const result = await this.$store.dispatch('eReport/deleteInnerEReport', { uuid: this.uuid, rowId: this.toBeDeleteId })
      if (result) {
        this.isFinishDelete = true
        this.toBeDeleteId = 0
        this.currentPage = 1
        await this.get()
      } else {
        this.isFailDelete = true
        this.toBeDeleteId = 0
      }
    },
    handleDialogSignal (e) {
      if (e !== this.openDialog) {
        this.openDialog = e
      }
    },
    onReportListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetching && this.hasNextPage) {
        this.currentPage += 1
        this.get()
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    parameterHeaders () {
      return Object.keys(this.apiParameter)
        .map(key => ({
          text: this.apiParameter[key].name,
          value: `parameters.${key}`,
          sortable: false,
          data_key: key,
          align: 'center'
        }))
    },
    dataList () {
      return Object.keys(this.apiMeasurement).map(key => (this.apiMeasurement[key]))
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .namespaced {
    width: calc(100% - 48px);
  }

  .headContainer {
    padding-left: 8px;
  }
}

@media (min-width: 600px) {
  .namespaced {
    width: 100%;
  }

  .headContainer {
    padding-left: 16px;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  /*height: unset;*/
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
